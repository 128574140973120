import { Document, Page, PDFViewer, View } from '@react-pdf/renderer';

import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { getPrintMobileChecklist } from 'v2/repositories/MobileChecklistRepository';

/* import principalImage from './GRANDE_000496_202406271523041227838598.jpg'; */
/* import motorImage from './modal_saolvar_SAOS.png'; */
/* import painelImage from './painel.jpg'; */

import { ItemChecklist } from './ItemsChecklist';
import { VehicleChecklist } from './VehicleChecklist';
import { ExternalChecklist } from './ExternalChecklist';
import { HeaderChecklist } from './HeaderChecklist';
import { CustomerChecklist } from './CustomerChecklist';
import { SaleChecklist } from './SaleChecklist';
import { styleChecklist } from './MobileChecklist.styles';
import { ConclusionChecklist } from './ConclusionChecklist';

//TODO: Corrigir tamanho das imagens

export function MobileChecklist({ params }) {
  const { LoadingComponent, fetchLoading } = useLoading();

  const [report, setReport] = useState(null);

  async function handleLoadData() {
    if (!params) return;
    try {
      const response = await getPrintMobileChecklist(params);
      setReport(response);
    } catch (error) {
      console.error(error);
      toastr.error('Erro ao carregar dados', error.message);
    }
  }

  useEffect(() => {
    fetchLoading(handleLoadData);
  }, [params]);

  useEffect(() => {}, [report]);

  return (
    <LoadingComponent>
      <PDFViewer
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      >
        <Document
          title={`impresso-checklist-${format(new Date(), 'dd/MM/yyyy')}`}
          subject={`Ordem de Serviço Nº:${report?.sale?.code}`}
        >
          <Page style={styleChecklist.page}>
            <View wrap={false}>
              <HeaderChecklist company={report?.company} />
            </View>
            <SaleChecklist sale={report?.sale} />
            <CustomerChecklist customer={report?.customer} />
            <VehicleChecklist
              external={report?.external}
              vehicle={report?.vehicle}
              internal={report?.internal}
            />
            <ExternalChecklist external={report?.external} />
            <View break>
              <ItemChecklist items={report?.items} />
            </View>
            <ConclusionChecklist conclusion={report?.conclusion} />
          </Page>
        </Document>
      </PDFViewer>
    </LoadingComponent>
  );
}
