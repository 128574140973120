import React, { useState } from 'react';

import DownloadXlsButton from 'components/DownloadXlsButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { SelectFamily } from 'v2/client/components/SelectFamily';

const ProductsWithoutMovementFilters = ({
  loading,
  handleSubmit,
  finalDate,
  initialDate,
  productType,
  products,
  movementType,
  brands,
  setFinalDate,
  setInitialDate,
  setProductType,
  setMovementType,
  setBrandId,
  setFamilyId,
  dataToExport,
  isExportReady,
  isExportLoading,
  handleProcessXLS,
}) => {
  const [familySelected, setFamilySelected] = useState(null);

  const handleFamilyChange = (item) => {
    setFamilySelected(item);
    setFamilyId(item && item.value ? item.value.id : '');
  };

  return (
    <>
      <div className="products-without-movement-filters">
        <div>
          <span>
            <strong>Data Inicial:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="initialDate"
            max={finalDate}
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </div>

        <div>
          <span>
            <strong>Data Final:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="finalDate"
            min={initialDate}
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </div>
      </div>
      <div className="products-without-movement-filters">
        <div
          style={{
            width: '15rem',
          }}
        >
          <span>
            <strong>Tipo de Produto:</strong>
          </span>
          <select
            className="form-control foco-input"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
          >
            <option value="Produto Final" selected>
              Produto Final
            </option>
            <option value="Uso e Consumo">Uso e Consumo</option>
            <option value="Matéria Prima">Matéria Prima</option>
          </select>
        </div>

        <div
          style={{
            width: '15rem',
          }}
        >
          <span>
            <strong>Tipo de Movimento:</strong>
          </span>
          <select
            className="form-control foco-input"
            value={movementType}
            onChange={(e) => setMovementType(e.target.value)}
          >
            <option value="Ambos" selected>
              Ambos
            </option>
            <option value="Entrada">Entrada</option>
            <option value="Saida">Saída</option>
          </select>
        </div>

        <div
          style={{
            width: '15rem',
          }}
        >
          <span>
            <strong>Marca:</strong>
          </span>
          <select
            className="form-control foco-input"
            onChange={(e) => setBrandId(e.target.value)}
          >
            <option value="" selected>
              Todas
            </option>
            {brands?.map((b) => {
              return (
                <option key={b.id} value={b.id}>
                  {b.Description.toUpperCase()}
                </option>
              );
            })}
          </select>
        </div>

        <div
          style={{
            width: '15rem',
          }}
        >
          <span>
            <strong>Família:</strong>
          </span>
          <SelectFamily
            value={familySelected}
            type="Produto"
            onChange={handleFamilyChange}
            width="100%"
            placeholder="Todas"
            isClearable
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <button className="btn btn-sucesso" onClick={handleSubmit}>
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Processar
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {isExportReady ? (
            <DownloadXlsButton
              archiveName="Produtos-sem-movimentação"
              data={dataToExport}
              className="btn btn-export"
              columns={[
                {
                  name: 'Código',
                  acessor: 'productCode',
                },
                {
                  name: 'Ref. Fabricante',
                  acessor: 'productManufacturerRef',
                },
                {
                  name: 'Descrição',
                  acessor: 'productDescription',
                },
                {
                  name: 'Família',
                  acessor: 'familyDescription',
                },
                {
                  name: 'Marca',
                  acessor: 'brandDescription',
                },
                {
                  name: 'Estoque',
                  acessor: 'productQuantity',
                },
                {
                  name: 'Preço de Compra',
                  acessor: 'productPurchasePriceF',
                },
                {
                  name: 'Margem',
                  acessor: 'productMarginF',
                },
                {
                  name: 'Preço de Venda',
                  acessor: 'productSalePriceF',
                },
              ]}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Baixar
              Relatório
            </DownloadXlsButton>
          ) : (
            <button
              className="btn btn-export"
              onClick={() => handleProcessXLS()}
              disabled={isExportLoading || products?.length === 0}
            >
              {isExportLoading ? (
                <span className="fa fa-spinner fa-pulse fa-1x"></span>
              ) : (
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              )}
              &nbsp;Exportar .xls
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsWithoutMovementFilters;
