import React, { useEffect, useState, memo } from 'react';
import { Col, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { Field, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { isFirefox } from '../../../../../utils/browserTypes';
import {
  description,
  description2,
  onlyNumbers,
  onlyNumbersCommaAndDot,
  specialChar2,
  onlyNumbersLetters,
  alfanumeric,
} from '../../../../components/ToNormalize/ToNormalize';
import config from 'config';
import api from 'services/api';
import Input from '../../../../components/Input/Input';
import ModalFormFamilie from '../../../Families/NewFamilie/ModalFormFamilie';
import ModalFormBrand from '../../../Brands/NewBrand/ModalNewBrand';
import ModalFormWarranty from '../../../Warranty/NewWarranty/ModalFormWarranty';
import ModalNewReview from '../../../Reviews/NewReview/ModalNewReview';
import familiesRepository from '../../../../../repositories/Families';
import warrantiesRepository from '../../../../../repositories/Warranties';
import brandsRepository from '../../../../../repositories/Brands';
import revisionsRepository from '../../../../../repositories/Revisions';
import { useAuth } from '../../../../../contexts/auth';
import './style.css';
import { SelectFamily } from 'v2/client/components/SelectFamily';

let Brands = ({ brands }) =>
  brands.map((b) => (
    <option key={b.id} value={b.id}>
      {b.Description}
    </option>
  ));

Brands = memo(Brands);

const MainData = ({ status, handleToggle, hasId }) => {
  const [familySelected, setFamilySelected] = useState(null);
  const [brands, setBrands] = useState([]);
  const [warranties, setWarranties] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [typeUnityItem, setTypeUnityItem] = useState([]);
  const [styleModal, setStyleModal] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const { companyId } = useAuth();
  const {
    Additional_Info,
    OriginalFactoryCode,
    AlternativeCode,
    familyDescription,
    Family,
  } = useSelector((state) => state.form.formProduto.values);
  const dispatch = useDispatch();

  useEffect(() => {
    loadDropDowns();
    loadTypeUnityItem();
  }, []);

  useEffect(() => {
    if (!AlternativeCode) {
      return;
    } else {
      dispatch(
        change(
          'formProduto',
          'AlternativeCode',
          alfanumeric(AlternativeCode.replace(/\s+/g, ''))
        )
      );
    }
  }, [AlternativeCode]);

  useEffect(() => {
    if (!OriginalFactoryCode) {
      return;
    } else {
      dispatch(
        change(
          'formProduto',
          'OriginalFactoryCode',
          alfanumeric(OriginalFactoryCode.replace(/\s+/g, ''))
        )
      );
    }
  }, [OriginalFactoryCode]);

  const loadDropDowns = async () => {
    loadWarranties();
    loadBrands();
    loadReviews();
  };

  const loadWarranties = async () => {
    try {
      const warranties = await warrantiesRepository.getAllByCompany(companyId);
      setWarranties(warranties);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as garantias. Por favor, tente novamente'
      );
    }
  };

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      setBrands(brands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadReviews = async () => {
    try {
      const revisions = await revisionsRepository.getAllByCompany(companyId);
      setReviews(revisions);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as revisões. Por favor, tente novamente'
      );
    }
  };

  const loadTypeUnityItem = async () => {
    const response = await api.get(config.endpoint + 'typeunity');
    setTypeUnityItem(response.data.length ? response.data : []);
  };

  const handleModal = () => {
    setOpenModal(false);
    loadDropDowns();
  };

  const handleFamilyChange = (item) => {
    setFamilySelected(item);
    dispatch(change('formProduto', 'Family', item.value.id));
  };

  const renderForm = () => {
    switch (modalTitle) {
      case 'Nova Família':
        return (
          <ModalFormFamilie
            Calling="Cadastro Produto"
            modal={true}
            handleModal={() => {
              setFamilySelected({
                label: familyDescription,
                value: { id: Family, description: familyDescription },
              });
              setOpenModal(false);
            }}
            dispatch={dispatch}
            readOnly={true}
          />
        );
      case 'Nova Marca':
        return (
          <ModalFormBrand
            Calling="Cadastro Produto"
            modal={true}
            handleModal={handleModal}
            dispatch={dispatch}
            readOnly={true}
            onlyProducts
          />
        );
      case 'Nova Garantia':
        return (
          <ModalFormWarranty
            Calling="Cadastro Produto"
            modal={true}
            handleModal={handleModal}
            dispatch={dispatch}
            readOnly={true}
          />
        );
      case 'Nova Revisão':
        return (
          <ModalNewReview
            Calling="Cadastro Produto"
            modal={true}
            handleModal={handleModal}
            dispatch={dispatch}
            readOnly={true}
          />
        );
    }
  };

  const handleOpenModal = async (title, style) => {
    setModalTitle(title);
    setStyleModal({
      ...style,
      marginLeft: '5px',
      marginTop: 0,
    });
    setOpenModal(true);
  };

  function handleEAN(value) {
    dispatch(change('formProduto', 'Tribute_BarCode', value));
  }

  const normalizeDescription = (value) => {
    return value ? value.toUpperCase() : value;
  };

  const handleDescriptionChange = (event) => {
    const { target } = event;
    const { value, selectionStart, selectionEnd } = target;
    target.value = value.toUpperCase();
    setTimeout(() => {
      target.setSelectionRange(selectionStart, selectionEnd);
    }, 0);
  };

  return (
    <Col id="form-product-main-data">
      <Col xs={8} sm={4} md={2} lg={1} style={{ paddingRight: '0' }}>
        <label>Código</label>
        <Field
          name="Code"
          type="text"
          disabled={true}
          style={{ textAlign: 'right' }}
          component={Input}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '0' }}>
        <label>
          Descrição<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          placeholder="Descrição"
          name="Description"
          type="text"
          component={Input}
          normalize={normalizeDescription}
          maxLength="100"
          onChange={handleDescriptionChange}
        />
      </Col>

      <Col
        xs={12}
        sm={6}
        md={6}
        lg={2}
        style={{ minWidth: '135px', paddingRight: '0' }}
      >
        <label>
          Unidade de Medida
          <span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          name="TypeUnityItem"
          type="text"
          component="select"
          className="form-control foco-input"
        >
          <option value="">Selecione</option>
          {typeUnityItem.map((r) => {
            return (
              <option key={r.id} value={r.Initials}>
                {r.Description}
              </option>
            );
          })}
        </Field>
      </Col>
      <Col
        xs={12}
        sm={2}
        md={2}
        lg={1}
        style={{ minWidth: '135px', paddingRight: '0' }}
      >
        <label>Estoque</label>
        <Field
          name="Stock_Quantity"
          type="text"
          normalize={onlyNumbersCommaAndDot}
          component={Input}
          disabled={!!hasId}
        />
      </Col>

      <Col xs={12} sm={1} md={1} lg={1} style={{ paddingRight: '0' }}>
        <label>Status</label>
        <div style={{ paddingLeft: '10px' }}>
          <Toggle checked={status} onChange={handleToggle} />
        </div>
      </Col>
      <Col xs={12} sm={6} md={6} lg={3} style={{ paddingRight: '0' }}>
        <div>
          <div className="div-label-modal">
            <label>
              Família<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
          <div className="div-a-modal">
            <a
              onClick={() =>
                handleOpenModal('Nova Família', { marginBottom: '140px' })
              }
            >
              Adicionar Família
            </a>
          </div>
        </div>
        <SelectFamily
          value={
            familySelected || Family
              ? {
                  label: familyDescription,
                  value: { id: Family, description: familyDescription },
                }
              : null
          }
          type="Produto"
          onChange={handleFamilyChange}
          width="100%"
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={3} style={{ paddingRight: '0' }}>
        <div>
          <div className="div-label-modal">
            <label>
              Marca<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
          <div className="div-a-modal">
            <a
              onClick={() =>
                handleOpenModal('Nova Marca', { marginBottom: '65px' })
              }
            >
              Adicionar Marca
            </a>
          </div>
        </div>
        <Field
          name="Brand"
          type="text"
          component="select"
          className="form-control foco-input"
        >
          <option value="">Selecione</option>
          <Brands brands={brands} />
        </Field>
      </Col>
      <Col xs={12} sm={6} md={6} lg={3} style={{ paddingRight: '0' }}>
        <div>
          <div className="div-label-modal">
            <label>Garantia</label>
          </div>
          <div className="div-a-modal">
            <a
              onClick={() =>
                handleOpenModal('Nova Garantia', {
                  height: '210px',
                  marginBottom: '65px',
                })
              }
            >
              Adicionar Garantia
            </a>
          </div>
        </div>
        <Field
          name="Warranty"
          type="text"
          component="select"
          className="form-control foco-input"
        >
          <option value="">Selecione</option>
          {warranties.map((w) => {
            return (
              <option key={w.id} value={w.id}>
                {w.Description}
              </option>
            );
          })}
        </Field>
      </Col>
      <Col xs={12} sm={6} md={6} lg={3} style={{ paddingRight: '0' }}>
        <div>
          <div className="div-label-modal">
            <label>Revisão</label>
          </div>
          <div className="div-a-modal">
            <a
              onClick={() =>
                handleOpenModal('Nova Revisão', {
                  marginBottom: isFirefox ? '185px' : '170px',
                })
              }
            >
              Adicionar Revisão
            </a>
          </div>
        </div>
        <Field
          name="Review"
          type="text"
          component="select"
          className="form-control foco-input"
        >
          <option value="">Selecione</option>
          {reviews.map((r) => {
            return (
              <option key={r.id} value={r.id}>
                {r.Description}
              </option>
            );
          })}
        </Field>
      </Col>

      <Col xs={12} sm={3} md={3} lg={3} style={{ paddingRight: '0' }}>
        <label>Localização</label>
        <Field
          name="Location"
          type="text"
          component={Input}
          normalize={description}
          maxLength="30"
        />
      </Col>

      <Col xs={12} sm={3} md={3} lg={3} style={{ paddingRight: '0' }}>
        <label>Ref. Fabricante</label>
        <Field
          name="Manufacturer_Ref"
          type="text"
          component={Input}
          normalize={specialChar2}
          maxLength="15"
        />
      </Col>

      <Col xs={12} sm={8} md={8} lg={3} style={{ paddingRight: '0' }}>
        <label>Cód. de Barras(EAN)</label>
        <Field
          name="Bar_Code"
          type="text"
          component={Input}
          normalize={onlyNumbers}
          maxLength="13"
          onChange={({ target: { value } }) => handleEAN(onlyNumbers(value))}
        />
      </Col>

      <Col xs={12} sm={8} md={8} lg={3} style={{ paddingRight: '0' }}>
        <label>Cód. de Barras(EAN - Tributável)</label>
        <Field
          name="Tribute_BarCode"
          type="text"
          component={Input}
          normalize={onlyNumbers}
          maxLength="13"
        />
      </Col>

      <Col xs={12} sm={8} md={8} lg={3} style={{ paddingRight: '0' }}>
        <label>Apelido</label>
        <Field name="surname" type="text" component={Input} maxLength="13" />
      </Col>

      <Col xs={12} sm={8} md={8} lg={3} style={{ paddingRight: '0' }}>
        <div>
          <label>Cód. Original de Fábrica</label>
          <Field name="OriginalFactoryCode" type="text" component={Input} />
        </div>
      </Col>

      <Col xs={12} sm={8} md={8} lg={3}>
        <div>
          <label>Código Alternativo</label>
          <Field
            name="AlternativeCode"
            type="text"
            component={Input}
            maxLength="18"
          />
        </div>
      </Col>

      <Col xs={12} sm={10} md={10} lg={10} className="field-hiperlink">
        <span>{!Additional_Info ? 0 : Additional_Info.length} caracteres</span>
        <label>Informações complementares</label>
        <Field
          className="form-control foco-input"
          name="Additional_Info"
          type="text"
          component="textarea"
          maxLength="650"
          style={{ resize: 'vertical', marginBottom: '22px' }}
        />
      </Col>

      <Modal
        dialogClassName="modal-formProduto"
        show={openModal}
        onHide={() => setOpenModal(false)}
        animation={true}
      >
        {renderForm()}
      </Modal>
    </Col>
  );
};

export default MainData;
