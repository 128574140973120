import React, { useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { faCircle, faEquals } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'contexts/auth';
import { useEffect } from 'react';
import kitsRepository from 'repositories/Kit';
import pdvRepository from 'repositories/PDVs';
import brandsRepository from 'repositories/Brands';
import productsRepository from 'repositories/Products';
import TableFipeRepository from 'repositories/TableFipe';
import familiesRepository from 'repositories/Families';
import { SearchModalTableWithInfiniteScroll } from '../SearchModalTableWithInfiniteScroll';
import ImagesModal from '../ProductsServicesTable/ImagesModal';
import { CaptionWithIcon } from 'v2/components/CaptionWithIcon/CaptionWithIcon';
import './styles.css';
import { getPricesOptions } from 'v2/helpers/priceHelpers';
import { useConfigurationsContext } from 'v2/contexts/configurationsContext';

const SearchModal = ({
  handleClose,
  vehicleModel,
  productQuery,
  additionalInformationQuery,
  handleAddItem,
  allowPromotionalPrices,
  searchType,
  setSearchType,
  setQuery,
  setSearchQuery,
  priceType,
  handleSetType,
}) => {
  const { companyId } = useAuth();

  const [brands, setBrands] = useState([]);
  const [manufactures, setManufactures] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const vehicleModelA = vehicleModel;
  const productQueryA = productQuery;

  const [kits, setKits] = useState([]);
  const [pagesKits, setPagesKits] = useState(0);
  const [kitSearchQuery, setKitSearchQuery] = useState('');

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [families, setFamilies] = useState([]);

  const [totalKits, setTotalKits] = useState(0);

  const [productQueryInput, setProductQueryInput] = useState('');
  const [vehicleModelInput, setVehicleModelInput] = useState('');
  const [yearVehicleInput, setYearVehicleInput] = useState('');
  const [additionalInformationInput, setAdditionalInformationInput] =
    useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [familyDescription, setFamilyDescription] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const [manufacturerDescription, setManufacturerDescription] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const [brandId, setBrandId] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const { sellingPriceType } = useConfigurationsContext();

  const [priceTypes, setPriceTypes] = useState([]);
  const [kitSearchDebounced, setKitSearchDebounced] = useState('');
  const [yearVehicleDebounced, setYearVehicleDebounced] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [productToVisualize, setProductToVisualize] = useState({});
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [brandDebounced, setBrandDebounced] = useState(brandId);
  const [manufacturerDebounced, setManufacturerDebounced] = useState(
    manufacturerDescription
  );
  const [additionalInformationDebounced, setAdditionalInformationDebounced] =
    useState(additionalInformationQuery);
  const [productQueryDebounced, setProductQueryDebounced] =
    useState(productQuery);
  const [vehicleModelDebounced, setVehicleModelDebounced] =
    useState(vehicleModelA);
  const [familyDebounced, setFamilyDebounced] = useState(familyDescription);

  const debouncedSaveFamily = useDebounce(setFamilyDebounced, 1000);
  const debouncedSaveBrand = useDebounce(setBrandDebounced, 1000);
  const debouncedSaveManufacturer = useDebounce(setManufacturerDebounced, 1000);
  const debouncedSaveQuery = useDebounce(setProductQueryDebounced, 1000);
  const debouncedSaveVehicleModel = useDebounce(setVehicleModelDebounced, 1000);
  const debouncedSaveAdditionalInformation = useDebounce(
    setAdditionalInformationDebounced,
    1000
  );
  const debouncedKitSearchQuery = useDebounce(setKitSearchDebounced, 1000);
  const debouncedSearchVehicleYear = useDebounce(setYearVehicleDebounced, 1000);

  function handleChangeProductQuery(value) {
    setProductQueryInput(value);
    debouncedSaveQuery(value);
  }

  function handleChangeVehicleQuery(value) {
    setVehicleModelInput(value);
    debouncedSaveVehicleModel(value);
  }

  function handleChangeFamily(value) {
    setFamilyDescription(value);
    debouncedSaveFamily(value);
  }

  function handleChangeAdditionalInformationQuery(value) {
    setAdditionalInformationInput(value);
    debouncedSaveAdditionalInformation(value);
  }

  function handleChangeBrand(value) {
    setBrandId(value);
    debouncedSaveBrand(value);
  }

  function handleChangeManufacturer(value) {
    setManufacturerDescription(value);
    debouncedSaveManufacturer(value);
  }

  function handleChangeQuerySearchKit(value) {
    setKitSearchQuery(value);
    debouncedKitSearchQuery(value);
  }

  function handleChangeYear(value) {
    const valueOnlyNumbers = value.replace(/\D/g, '');

    if (value === '') {
      setYearVehicleInput('');
      debouncedSearchVehicleYear('');
      return;
    }

    const firstDigit = Number(valueOnlyNumbers[0]);

    if (firstDigit > 2 || firstDigit === 0) {
      setYearVehicleInput('');
      debouncedSearchVehicleYear('');

      return toastr.warning(
        'Ano inválido',
        'Insira um ano válido para aplicação e tente novamente'
      );
    }

    setYearVehicleInput(valueOnlyNumbers);
    debouncedSearchVehicleYear(valueOnlyNumbers);
  }

  const handleOpenProductImageModal = async (product) => {
    try {
      const productsPhotos = await productsRepository.getPhotos(
        product.productId
      );

      if (!productsPhotos.length)
        return toastr.warning(
          'Produto sem foto',
          'Nenhuma foto foi identificada para esse produto'
        );

      setProductToVisualize({ ...product, ProductsPhotos: productsPhotos });
      setIsImagesModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as fotos do produto',
        'Por favor, tente novamente'
      );
    }
  };

  const handleFetchMoreData = () => {
    if (
      !isLoadingMore &&
      (currentPage < totalPages || currentPage < pagesKits)
    ) {
      setIsLoadingMore(true);

      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        return nextPage;
      });
    }
  };

  const loadFamilies = async () => {
    try {
      //TODO: viabilizar remover o limit 9999(select multi async)
      const families = await familiesRepository.getAllByCompany(companyId, {
        page: 1,
        limit: 9999,
        Type: 'Produto',
      });

      const serializedFamilies = families.rows.map(
        (family) => ({
          label: family.Description,
          value: family.id,
        }),
        [families]
      );

      serializedFamilies.unshift({ value: '*', label: 'Todas' });
      setFamilies(serializedFamilies);
      setFamilyDescription(serializedFamilies);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  async function loadKits() {
    setLoading(true);

    try {
      const response = await kitsRepository.getAll({
        companyId,
        status: 1,
        page: currentPage,
        limit: pageLimit,
        query: kitSearchDebounced,
      });

      const serializedKits = response.kits.rows.map((kit) => ({
        Products: response.kitItemsProducts.filter(
          (product) => product.kitId === kit.id
        ),
        Services: response.kitItemsServices.filter(
          (service) => service.kitId === kit.id
        ),
        productDescription: kit.description,
        productSalePrice: kit.priceTotal,
        productCode: '---',
        productManufacturerRef: '---',
        productBarCode: kit.barCode,
        productQuantity: '---',
        brandDescription: '---',
        vehicleModel: '---',
        productLocation: '---',
      }));

      if (currentPage === 1) {
        setKits(serializedKits);
      } else {
        setKits([...kits, ...serializedKits]);
      }
      setTotalKits(response.kits.count);
      setPagesKits(Math.ceil(response.kits.count / pageLimit));
    } catch (err) {
      console.error(err);
      if (!kits) {
        toastr.warning(
          'Ocorreu um erro ao buscar os kits. Por favor, tente novamente.'
        );
      }
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  }

  const handleSearchProducts = async () => {
    setLoading(true);
    try {
      const isAllBrandIdSelected = brandId.some((brand) => brand.value === '*');
      const brandIds = brandId
        .filter((brand) => brand.value !== '*')
        .map((brand) => brand.value)
        .join(',');

      const isAllManufacturerDescriptionSelected = manufacturerDescription.some(
        (manufacturer) => manufacturer.value === '*'
      );
      const manufacturesDescriptions = manufacturerDescription
        .filter((manufacturer) => manufacturer.value !== '*')
        .map((manufacturer) => manufacturer.value)
        .join(',');

      const params = {
        limit: pageLimit,
        page: currentPage,
        productQueryA: productQueryA ? productQueryA : productQueryDebounced,
        vehicleModelA: vehicleModelA ? vehicleModelA : vehicleModelDebounced,
        productQueryB: productQueryDebounced,
        vehicleModelB: vehicleModelDebounced,
        additionalInformation: additionalInformationDebounced,
      };

      if (!isAllBrandIdSelected && brandIds.length !== 0) {
        params.brandsIds = `[${brandIds}]`;
      }

      if (
        !isAllManufacturerDescriptionSelected &&
        manufacturesDescriptions.length !== 0
      ) {
        params.manufacturesDescriptions = `[${manufacturesDescriptions}]`;
      }

      if (!!yearVehicleDebounced) {
        params.yearVehicle = yearVehicleDebounced;
      }

      const isAllFamilyDescriptionSelected = familyDescription.some(
        (family) => family.value === '*'
      );

      const familiesDescriptions = familyDescription
        .filter((family) => family.value !== '*')
        .map((family) => family.value)
        .join(',');

      if (
        !isAllFamilyDescriptionSelected &&
        familiesDescriptions.length !== 0
      ) {
        params.familiesDescriptions = `[${familiesDescriptions}]`;
      }

      const response = await pdvRepository.getProducts(companyId, params);

      setTotalProducts(response.count);
      if (currentPage === 1) {
        setData(response.products);
      } else {
        setData([...data, ...response.products]);
      }
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Não foi possível realizar a busca de produtos.',
        'Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleResetSearch = () => {
    setKits([]);
    setKitSearchDebounced('');
    setQuery('');
    setSearchQuery('');
    setProductQueryDebounced('');
    setVehicleModelDebounced('');
    setProductQueryInput('');
    setVehicleModelInput('');
    setAdditionalInformationInput('');
    setKitSearchQuery('');
    setYearVehicleInput('');
    setManufacturerDescription(manufactures);
    setManufacturerDebounced(manufactures);
    setBrandId(brands);
    setBrandDebounced(brands);
  };

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      const serializedBrands = brands.map(
        (brand) => ({
          label: brand.Description,
          value: brand.id,
        }),
        [brands]
      );

      serializedBrands.unshift({ value: '*', label: 'Todas' });
      setBrands(serializedBrands);
      setBrandId(serializedBrands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadManufacturers = async () => {
    try {
      const manufactures = await TableFipeRepository.getBrands();

      const serializedManufactures = manufactures.data.map(
        (brand) => ({
          label: brand.Brand,
          value: brand.Brand,
        }),
        [manufactures]
      );

      serializedManufactures.unshift({ value: '*', label: 'Todas' });
      setManufactures(serializedManufactures);
      setManufacturerDescription(serializedManufactures);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  useEffect(() => {
    if (!loading) {
      if (data.length === 1 && !isOpen) {
        handleAddItem(data[0]);
        return;
      }

      if (data.length === 0 && productQuery) {
        toastr.warning(
          'Produto não localizado',
          'Não foi localizado nenhum produto com essa informação. Refaça a pesquisa e tente novamente'
        );

        if (!isOpen) {
          handleClose();
        }
        return;
      }

      setIsOpen(true);
    }
  }, [data, loading]);

  useEffect(() => {
    if (isLoadingMore) {
      if (searchType === 'Produtos') {
        handleSearchProducts().finally(() => setIsLoadingMore(false));
      } else {
        loadKits().finally(() => setIsLoadingMore(false));
      }
      setHasScrolled(false);
    }
  }, [currentPage, isLoadingMore, searchType]);

  useEffect(() => {
    loadBrands();
    loadFamilies();
    loadManufacturers();
    setPriceTypes(getPricesOptions(sellingPriceType, false, false));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setIsLoadingMore(true);
  }, [
    productQueryDebounced,
    vehicleModelDebounced,
    additionalInformationDebounced,
    brandDebounced,
    manufacturerDebounced,
    searchType,
    kitSearchDebounced,
    familyDebounced,
    yearVehicleDebounced,
  ]);

  return (
    <>
      <Modal
        dialogClassName="modal-90w"
        show={isOpen}
        onHide={handleClose}
        animation
      >
        <Modal.Body>
          <SearchModalTableWithInfiniteScroll
            loading={loading}
            handleClose={handleClose}
            data={data}
            totalProducts={totalProducts}
            totalKits={totalKits}
            totalPages={totalPages}
            currentPage={currentPage}
            families={families}
            familyDescription={familyDescription}
            priceType={priceType}
            priceTypes={priceTypes}
            handleSetType={handleSetType}
            brandId={brandId}
            manufacturerDescription={manufacturerDescription}
            productQuery={productQueryInput}
            setProductQueryDebounced={setProductQueryDebounced}
            vehicleModel={vehicleModelInput}
            additionalInformation={additionalInformationInput}
            setVehicleModelDebounced={setVehicleModelDebounced}
            handleChangeProductQuery={handleChangeProductQuery}
            handleChangeVehicleQuery={handleChangeVehicleQuery}
            handleChangeAdditionalInformationQuery={
              handleChangeAdditionalInformationQuery
            }
            handleChangeBrand={handleChangeBrand}
            handleChangeManufacturer={handleChangeManufacturer}
            handleAddItem={handleAddItem}
            handleChangeFamily={handleChangeFamily}
            allowPromotionalPrices={allowPromotionalPrices}
            handleChangeQuerySearchKit={handleChangeQuerySearchKit}
            kits={kits}
            pagesKits={pagesKits}
            searchType={searchType}
            setSearchType={setSearchType}
            kitSearchQuery={kitSearchQuery}
            brands={brands}
            manufactures={manufactures}
            handleChangeYear={handleChangeYear}
            yearVehicle={yearVehicleInput}
            handleOpenProductImageModal={handleOpenProductImageModal}
            handleSearchProducts={handleSearchProducts}
            isLoadingMore={isLoadingMore}
            handleFetchMoreData={handleFetchMoreData}
            hasScrolled={hasScrolled}
            setHasScrolled={setHasScrolled}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 flex between">
            <div className="w-100 flex column start align-start">
              <CaptionWithIcon icon={faCircle} color="#d9534f">
                Produtos com estoque zerado ou negativo
              </CaptionWithIcon>
              <CaptionWithIcon icon={faCircle} color="#4cae4c">
                Produtos com preço promocional
              </CaptionWithIcon>
              <CaptionWithIcon icon={faEquals} color="#4cae4c">
                Produtos com similaridade
              </CaptionWithIcon>
            </div>
            <div className="flex between gap-050 w-250px">
              <button
                className="button button-yellow button-h35 flex center"
                onClick={handleResetSearch}
                disabled={isLoadingMore}
              >
                <span className="flex center">Limpar Pesquisa</span>
              </button>
              <button
                className="button button-red button-h35 flex center"
                onClick={() => handleClose()}
              >
                <span>Voltar(ESC)</span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {isImagesModalOpen && (
        <ImagesModal
          onHide={() => setIsImagesModalOpen(false)}
          onCancel={() => setIsImagesModalOpen(false)}
          productDescription={productToVisualize.Description}
          productPhotos={productToVisualize.ProductsPhotos}
        />
      )}
    </>
  );
};

export default SearchModal;
