import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';

import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import SearchInput from 'components/SearchInput';

import { useAuth } from 'contexts/auth';

import brandsRepository from 'repositories/Brands';
import familiesRepository from 'repositories/Families';
import { SelectFamily } from 'v2/client/components/SelectFamily';

const SearchInventoryModalTable = ({
  loading,
  data,
  totalPages,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  brandId,
  setBrandId,
  productQuery,
  handleChangeProductQuery,
  handleAddProduct,
  familyId,
  setFamilyId,
}) => {
  const [brands, setBrands] = useState([]);
  const [familySelected, setFamilySelected] = useState(null);

  const { companyId } = useAuth();

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      setBrands(brands);
    } catch (err) {
      toastr.warning(
        'OS Digital',
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente!'
      );
    }
  };

  const handleFamilyChange = (item) => {
    setFamilySelected(item || null);
    setFamilyId(item && item.value ? item.value.id : null);
  };

  useEffect(() => {
    loadBrands();
  }, []);

  return (
    <div id="search-modal-content">
      <div className="search-modal-input">
        <SearchInput
          placeholder="Pesquisa por Descrição, Código, Ref. Fabricante, Cód. de Barras e Apelido"
          value={productQuery}
          onChange={(e) => handleChangeProductQuery(e.target.value)}
        />

        <select
          className="form-control foco-input"
          type="text"
          style={{
            width: '15rem',
          }}
          value={brandId}
          onChange={(e) => setBrandId(e.target.value)}
        >
          <option value="" selected>
            Marca
          </option>
          {brands &&
            brands.map((r) => {
              return (
                <option key={r.id} value={r.id}>
                  {r.Description}
                </option>
              );
            })}
        </select>

        <SelectFamily
          value={familySelected}
          type="Produto"
          onChange={handleFamilyChange}
          width="20rem"
          isClearable
          placeholder="Família"
        />
      </div>
      <ReactTable
        style={{
          fontSize: 12,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={data}
        columns={[
          {
            Header: '',
            width: Math.round((window.innerWidth - 55) * 0.03),
            Cell: (props) => (
              <FontAwesomeIcon
                style={{
                  width: 18,
                  height: 18,
                  color: 'green',
                  cursor: 'pointer',
                }}
                icon={faCirclePlus}
                onClick={() => handleAddProduct(props.original)}
              />
            ),
          },
          {
            Header: 'Código',
            accessor: 'productCode',
            minWidth: 80,
          },
          {
            Header: 'Descrição',
            accessor: 'productDescription',
            minWidth: 200,
          },
          {
            Header: 'Ref. Fabricante',
            accessor: 'productManufacturerRef',
            minWidth: 100,
            resizable: false,
          },
          {
            Header: 'Estoque',
            accessor: 'productQuantity',
            minWidth: 100,
          },
          {
            Header: 'Código de Barras',
            accessor: 'productBarCode',
            minWidth: 150,
          },
          {
            Header: 'Marca',
            accessor: 'brandDescription',
            minWidth: 150,
          },
          {
            Header: 'Família',
            accessor: 'familyDescription',
            minWidth: 150,
          },
          {
            Header: 'Localização',
            accessor: 'productLocation',
            minWidth: 100,
            resizable: false,
          },
          {
            Header: 'R$ Compra',
            accessor: 'productPurchasePrice',
            width: Math.round((window.innerWidth - 55) * 0.09),
            minWidth: 100,
            Cell: (props) => <a>{currency(props.value)}</a>,
          },
        ]}
        defaultPageSize={10}
        pages={totalPages}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        manual
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        sortable={false}
        noDataText="Nenhum produto encontrado"
        loading={loading}
        loadingText="Carregando..."
      />
    </div>
  );
};

export default SearchInventoryModalTable;
