import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrashAlt,
  faCloudDownloadAlt,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import SearchInput from '../../../components/SearchInput';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from '../../../contexts/auth';
import useDebounce from '../../../hooks/useDebounce';

import constants from '../../../utils/constants';
import AlertModal from 'components/AlertModal';

import servicesRepository from '../../../repositories/Services';

import { xlsColumns, getServicesToExport } from './excelHelpers';
import DownloadXlsButton from '../../../components/DownloadXlsButton';
import { format } from 'date-fns';

import familiesRepository from '../../../repositories/Families';
import warrantiesRepository from '../../../repositories/Warranties';
import revisionsRepository from 'repositories/Revisions';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import ImportFromXlsModal from 'client/components/ImportFromXlsModal';

const HomeServices = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [loadingExportData, setLoadingExportData] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceId, setServiceId] = useState();
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [warranties, setWarranties] = useState([]);
  const [revisions, setRevisions] = useState([]);

  const { companyId } = useAuth();
  const [query, setQuery] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [status, setStatus] = useState();

  const [isImportFromXlsModalOpen, setIsImportFromXlsModalOpen] =
    useState(false);
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const [allDataToExport, setAllDataToExport] = useState([]);
  const [servicesInfoToExport, setServicesInfoToExport] = useState([]);
  const [loadedExport, setLoadedExport] = useState(false);

  const debouncedSaveQuerySearch = useDebounce(setSearchQuery, 800);

  useEffect(() => {
    if (!!companyId) {
      loadServices({
        page: 0,
        pageSize,
        query: searchQuery,
        status,
      });
    }
  }, [status, searchQuery]);

  useEffect(() => {
    if (!!companyId && loading && allDataToExport.length > 0) {
      loadAllServicesExport({
        query: searchQuery,
        status,
      });
    }
  }, [services, status, query, searchQuery]);

  useEffect(() => {
    const servicesInfoToExportData = getServicesToExport(
      allDataToExport,
      revisions,
      warranties
    );
    setServicesInfoToExport(servicesInfoToExportData);
  }, [allDataToExport]);

  useEffect(() => {
    loadWarranties();
    loadRevisions();
  }, []);

  const loadWarranties = async () => {
    try {
      const warranties = await warrantiesRepository.getAllByCompany(companyId);
      setWarranties(warranties);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as garantias. Por favor, tente novamente'
      );
    }
  };

  const loadRevisions = async () => {
    try {
      const revisions = await revisionsRepository.getAllByCompany(companyId);
      setRevisions(revisions);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as revisões. Por favor, tente novamente.'
      );
    }
  };

  async function loadServices(state) {
    setLoading(true);
    try {
      const response = await servicesRepository.getAllPaginated({
        Company_id: companyId,
        page: state.page + 1,
        limit: state.pageSize,
        IsActive: state.status,
        query: state.query,
      });

      const total = response.headers['x-total-count'];

      setPages(Math.floor(total / state.pageSize) + 1);
      setServices(response.data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os serviços. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function loadAllServicesExport(state) {
    setLoadingExportData(true);
    try {
      const response = await servicesRepository.getAllPaginated({
        Company_id: companyId,
        IsActive: state.status,
        query: state.query,
      });
      setAllDataToExport(response.data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os serviços. Por favor, tente novamente'
      );
    } finally {
      setLoadingExportData(false);
    }
  }

  function loadXlsExport(state) {
    setLoadedExport(true);
    loadAllServicesExport(state);
  }

  function handleChangeQuerySearch(value) {
    setQuery(value);
    debouncedSaveQuerySearch(value);
  }

  const handleDelete = async (service) => {
    try {
      const { isServiceEligibleForExclusion } =
        await servicesRepository.verifyServiceExclusionEligibility({
          serviceId: service.id,
          companyId,
        });

      if (isServiceEligibleForExclusion) {
        setServiceId(service.id);
        setShowDeleteServiceModal(true);
      } else {
        toastr.warning(
          'OS Digital',
          'O serviço possui vínculo com venda. Enquanto houver vínculos, não será possível apagar o cadastro.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao verificar eligibilidade de exclusão',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const deleteService = async () => {
    try {
      await servicesRepository.deleteService(serviceId);
      toastr.success('Serviço excluído com sucesso.');
      loadServices({
        page,
        pageSize,
        query: searchQuery,
        status,
      });
    } catch (err) {
      toastr.error('Ocorreu um erro ao deletar o serviço.');
    } finally {
      setShowDeleteServiceModal(false);
    }
  };

  return (
    <>
      <Card
        content={
          <div>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  className="btn btn-sucesso button-h35"
                  onClick={() =>
                    !!companyId
                      ? history.push(constants.ROUTES.SERVICE)
                      : setNoCompanyModal(true)
                  }
                  style={{ fontSize: '12px' }}
                >
                  + Novo Serviço
                </button>
              </div>
              <div>
                <SearchInput
                  placeholder="Pesquisa por Código ou Descrição"
                  value={query}
                  onChange={(e) => handleChangeQuerySearch(e.target.value)}
                  background={'#FFFFFF'}
                  style={{
                    fontSize: '12px',
                    height: '35px',
                    width: '312px',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  name="searchStatus"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Ambos</option>
                  <option value="1">Ativo</option>
                  <option value="0">Inativo</option>
                </select>
              </div>
              <div>
                {loadedExport ? (
                  <DownloadXlsButton
                    archiveName={`Serviços - ${format(
                      new Date(),
                      'dd-MM-yyyy-HH-mm'
                    )}`}
                    data={servicesInfoToExport}
                    className="btn btn-export"
                    disabled={loadingExportData || !services.length}
                    columns={xlsColumns}
                    style={{ fontSize: '12px' }}
                  >
                    {loadingExportData ? (
                      <span className="fa fa-spinner fa-pulse fa-1x"></span>
                    ) : (
                      <FontAwesomeIcon
                        color="white"
                        icon={faCloudDownloadAlt}
                      />
                    )}
                    &nbsp;Exportar .xls
                  </DownloadXlsButton>
                ) : (
                  <button
                    className="btn btn-sucesso button-h35"
                    onClick={() =>
                      loadXlsExport({ query: searchQuery, status })
                    }
                    style={{ fontSize: '12px' }}
                  >
                    <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
                    &nbsp;Processar Exportação xls
                  </button>
                )}
              </div>

              {!isPlanFree && !isPlanStart ? (
                <div>
                  <button
                    className="btn button-h35"
                    onClick={() => setIsImportFromXlsModalOpen(true)}
                    title="Importar Serviços"
                    style={{
                      backgroundColor: '#5bc0de',
                      borderColor: '#5bc0de',
                      color: '#FFFFFF',
                      fontSize: '12px',
                    }}
                  >
                    <FontAwesomeIcon color="white" icon={faFileUpload} />
                    &nbsp;Importar Serviços
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
            <div>
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '12px',
                }}
                data={services}
                columns={[
                  {
                    Header: 'Código',
                    id: 'Code',
                    accessor: (props) => Number(props.Code),
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Descrição',
                    accessor: 'Description',
                    headerClassName: 'text-left',
                    width: 300,
                  },
                  {
                    Header: 'Valor (R$)',
                    accessor: 'Price',
                    headerClassName: 'text-left',
                    Cell: (props) => <a>{currency(props.value)}</a>,
                  },
                  {
                    Header: 'Horas',
                    accessor: 'Hours_Expected',
                    headerClassName: 'text-left',
                    Cell: (props) => (
                      <a>{!props.value ? '000:00' : props.value}Hs</a>
                    ),
                  },
                  {
                    Header: 'Status',
                    accessor: 'IsActive',
                    headerClassName: 'text-left',
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor: props.value ? 'green' : 'red',
                        }}
                      >
                        {props.value ? 'Ativo' : 'Inativo'}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'Actions',
                    headerClassName: 'text-left',
                    filterable: false,
                    Cell: (props) =>
                      !!companyId ? (
                        <>
                          <Link
                            to={{ pathname: `service#${props.original.id}` }}
                          >
                            <FontAwesomeIcon
                              title="Editar"
                              cursor="pointer"
                              style={{
                                height: '1.5em',
                                width: '1.5em',
                                color: 'black',
                                marginRight: '5px',
                              }}
                              icon={faEdit}
                            />
                          </Link>
                          <FontAwesomeIcon
                            title="Excluir"
                            cursor="pointer"
                            style={{
                              height: '1.5em',
                              width: '1.5em',
                              color: '#bd362f',
                              marginRight: '5px',
                            }}
                            icon={faTrashAlt}
                            onClick={() => handleDelete(props.original, pages)}
                          />
                        </>
                      ) : (
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="false"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setNoCompanyModal(true)}
                        />
                      ),
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination={true}
                sortable={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                pages={pages}
                manual
                defaultSorted={[
                  {
                    id: 'Description',
                    desc: false,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Não há informação"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                onFetchData={(state, _) =>
                  loadServices({
                    ...state,
                    query: searchQuery,
                    status,
                  })
                }
                onPageChange={(page) => setPage(page)}
                onPageSizeChange={(newPageSize, _) => setPageSize(newPageSize)}
              />
            </div>
          </div>
        }
      />
      {
        <AlertModal
          show={showDeleteServiceModal}
          title="OS Digital"
          message="Você tem certeza que deseja excluir o serviço cadastrado? A exclusão é irrevesível e deverá cadastrar o serviço novamente se necessário."
          onHide={() => setShowDeleteServiceModal(false)}
          onCancel={() => setShowDeleteServiceModal(false)}
          onSubmit={deleteService}
          loading={loading}
        />
      }
      <ImportFromXlsModal
        show={isImportFromXlsModalOpen}
        onCancel={() => setIsImportFromXlsModalOpen(false)}
        importType="services"
        loadItems={loadServices}
        loadItemsParams={{
          page: 0,
          pageSize,
          query: searchQuery,
          status,
        }}
      />
    </>
  );
};

export default withRouter(HomeServices);
