import { StyleSheet } from '@react-pdf/renderer';

export const styleChecklist = StyleSheet.create({
  mainTitle: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  fontSize14: {
    fontSize: 14,
  },
  span: {
    fontSize: 9,
  },
  text: {
    fontSize: 7,
  },
  p: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  spaceColumn: {
    marginBottom: 10,
  },
  spaceRow: {
    marginRight: 10,
  },
  flexRow: {
    flexDirection: 'row',
  },
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    borderBottom: '1px solid black',
    marginTop: '5px',
    marginBottom: '5px',
  },
  lineColumn: {
    borderRight: '1px solid black',
    marginLeft: '5px',
    marginRight: '5px',
  },
  categoryTitle: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  center: {
    alignItems: 'center',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
  content: {
    marginTop: 100, // Espaço para o header fixo
    flexGrow: 1, // Faz com que o conteúdo ocupe o espaço disponível
  },
});
