import React from 'react';

import { Text, View, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import { useMobileChecklist } from './hooks/useMobileChecklist';
import { styleChecklist } from './MobileChecklist.styles';

const Icon = ({ icon, color = 'white', size = 12 }) => {
  if (!icon) {
    return <Text style={{ color }}>N/A</Text>;
  }

  return (
    <Svg
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <Path d={icon.icon[4]} fill={color} stroke={color} strokeWidth={1} />
    </Svg>
  );
};

const ButtonComponent = ({ icon, color, borderColor, isSelected, label }) => {
  const getOpacity = () => {
    if (label) {
      return 1;
    }
    return isSelected ? 1 : 0.3;
  };
  return (
    <View
      break
      style={{
        backgroundColor: color,
        borderColor: !isSelected ? borderColor : 'none',
        opacity: getOpacity(),
        width: label ? 85 : 30,
        height: 30,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 5,
        flexDirection: 'row',
        padding: label ? 5 : 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon ? (
        <Icon icon={icon} color="white" />
      ) : (
        <Text
          style={[
            { color: 'white', fontWeight: 'bold', opacity: 1 },
            styleChecklist.fontSize14,
          ]}
        >
          N/A
        </Text>
      )}

      {label && (
        <Text
          style={[
            { color: 'white', fontWeight: 'bold', marginLeft: '8px' },
            styleChecklist.span,
          ]}
        >
          {label}
        </Text>
      )}
    </View>
  );
};
export const ItemChecklist = ({ items }) => {
  const { buttonIconData } = useMobileChecklist();

  return (
    <View style={[styleChecklist.line]}>
      <View
        style={[
          styleChecklist.flexRow,
          styleChecklist.spaceRow,
          styleChecklist.center,
          styleChecklist.line,
          { width: '100%' },
        ]}
      >
        <View
          style={[
            styleChecklist.flexRow,
            {
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 10,
            },
          ]}
        >
          <Text style={[styleChecklist.categoryTitle]}>Legenda:</Text>

          {buttonIconData?.map((button) => (
            <View key={button.id}>
              <ButtonComponent
                label={button.id}
                icon={button.icon}
                color={button.backgroundColor}
                borderColor={button.borderColor}
              />
            </View>
          ))}
        </View>
      </View>

      {items &&
        items.map((categoryItem, index) => {
          const { description, items: categoryItems } = categoryItem;

          if (!categoryItems || categoryItems.length === 0) return null;

          return (
            <View key={index}>
              <Text style={[styleChecklist.categoryTitle, styleChecklist.line]}>
                {description}
              </Text>
              <View style={[styles.grid]}>
                {categoryItems.map((item, idx) => {
                  return (
                    <>
                      <View
                        key={`idx-${idx}-${item}`}
                        style={[
                          styles.gridItem,
                          {
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          },
                        ]}
                      >
                        <View style={[styleChecklist.center]}>
                          <Text style={[styleChecklist.span]}>
                            {item.description}
                          </Text>
                        </View>
                        <View style={[{ flexDirection: 'column' }]}>
                          <View style={[{ flexDirection: 'row' }]}>
                            {buttonIconData?.map((button) => {
                              const isSelected = button.status === item.status;
                              return (
                                <>
                                  <ButtonComponent
                                    key={button.id}
                                    icon={button.icon}
                                    color={button.backgroundColor}
                                    borderColor={button.borderColor}
                                    isSelected={isSelected}
                                  />
                                </>
                              );
                            })}
                          </View>
                          {item.observations ? (
                            <View
                              style={[
                                {
                                  fontStyle: 'italic',
                                  marginTop: 5,
                                  width: '160px',
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styleChecklist.span,
                                  {
                                    wordWrap: 'break-word',
                                    display: 'flex',
                                    overflowWrap: 'break-word',
                                  },
                                ]}
                              >
                                Observação:
                                {item.observations}
                              </Text>
                            </View>
                          ) : (
                            <View />
                          )}
                        </View>
                      </View>
                    </>
                  );
                })}
              </View>
            </View>
          );
        })}
    </View>
  );
};

// Estilização do PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: 'Roboto',
    width: '100%',
  },
  title: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: 'center',
  },
  legendContainer: {
    marginBottom: 20,
  },
  legendItems: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },

  legendDescription: {
    marginLeft: 5,
    fontSize: 12,
  },
  categoryContainer: {
    marginBottom: 20,
  },

  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  gridItem: {
    width: '48%',
    marginBottom: 10,
    flexDirection: 'row',
  },
  itemName: {
    fontSize: 8,
    marginBottom: 5,
  },
  buttonsRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',

    /*  marginBottom: 10,
    paddingBottom: 5,
    borderBottomWidth: 1, */
    /* borderBottomColor: 'black', */
  },
});
